import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inactivityBasedOffButton', 'inactivityBasedOnButton']

  visibilityFeatureTurnedOn() {
    this.inactivityBasedOffButtonTarget.checked = false
    this.inactivityBasedOnButtonTarget.checked = true
    document.getElementById('inactivityBasedSection').classList.remove('hide')
    document.getElementById('inactivityPeriodSection').classList.remove('hide')
  }

  visibilityFeatureTurnedOff() {
    this.inactivityBasedOffButtonTarget.checked = true
    this.inactivityBasedOnButtonTarget.checked = false
    document.getElementById('inactivityBasedSection').classList.add('hide')
  }

  inactivityBasedTurnedOn() {
    document.getElementById('inactivityPeriodSection').classList.remove('hide')
  }

  inactivityBasedTurnedOff() {
    document.getElementById('inactivityPeriodSection').classList.add('hide')
  }
}
