import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    maxOfferQuantity: Number
  }
  static targets = [
    'locationQuantityInput',
    'totalAllocatedQuantity'
  ]

  connect() {
    let total = 0;

    this.locationQuantityInputTargets.forEach(input => {
        total += parseInt(input.value) || 0;
    });

    this.totalAllocatedQuantityTarget.textContent = total;
  }

  updateTotalAllocation() {
    let total = 0;

    this.locationQuantityInputTargets.forEach(input => {
        total += parseInt(input.value) || 0;
    });

    if (total == 0 || total == parseInt(this.maxOfferQuantityValue)) {
      this.totalAllocatedQuantityTarget.style.color = 'black';
      this.totalAllocatedQuantityTarget.textContent = total;
    } else {
      this.totalAllocatedQuantityTarget.style.color = 'red';
      this.totalAllocatedQuantityTarget.textContent = I18n.t('offers.max_qty_location_allocation_error_msg', {limit: this.maxOfferQuantityValue});
    }
  }
}