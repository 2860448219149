import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Methods

  bulkActivate(e) {
    this.sendBulkUpdateAjaxRequest('activate', e.target.dataset.url)
  }

  bulkDeactivate(e) {
    this.sendBulkUpdateAjaxRequest('deactivate', e.target.dataset.url)
  }

  // Private

  sendBulkUpdateAjaxRequest(status, url) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: {
        promotion_bundle_ids: this.getSelectedPromotionBundleIds()
      },
      success(data) {
        if (status == 'activate') {
          $('#bulkActivateBundlesConfirm').modal('hide')
        } else {
          $('#bulkDeactivateBundlesConfirm').modal('hide')
        }
        alertify.success(data.message)

        setTimeout(location.reload(), 1200)
      },
      error(jqXHR, textStatus, errorThrown) {
        alertify.error(
          I18n.t('javascript.promotion_bundles_status', {
            status: status
          })
        )
      }
    })
  }

  getSelectedPromotionBundleIds() {
    return $('.promotion-bundle-listing-table tbody input[type="checkbox"]:checked').map(function () {
      return this.value
    }).get();
  }
}
