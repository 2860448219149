import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'

const BOTTOM_BOUNDING = 40
const TOP_BOUNDING = 90
const ROOT_MARGIN = `${TOP_BOUNDING}px 0px ${BOTTOM_BOUNDING}px 0px`

export default class extends Controller {
  static targets = ['loadProductDetails', 'sellerCount']

  static values = {
    ddi: String,
    expandProductDetailsInProgress: Boolean,
    source: String,
    ndcSearch: Boolean,
    firstProduct: Boolean,
    collapseTarget: String,
    regularSellerCount: Number,
    p2pSellerCount: Number
  }

  initialize() {
    this.ndc = ''
    this.keywords = ''
    this.p2pOnly = ''
    this.hide_p2p = ''
    this.hideNonNabp = ''
    this.sortBy = ''
    this.sortDir = ''
    this.showShortDate = ''
    this.hideShortDate = ''
    this.manufacturer = ''
    this.seller_name = ''
    this.showRegularSellers = false
  }

  connect() {
    this.loadFiltersFromURL()
    if (
      (this.ndc || this.keywords) &&
      this.firstProductValue &&
      this.sourceValue !== 'recently-viewed-ddi'
    ) {
      const targetVal = this.collapseTargetValue
      $(`#${targetVal}`).collapse()
      this.expandProductDetails()
    }

    const self = this
    document.addEventListener('supplier-products:entriesLoaded', () => {
      $.addTitleIfLineClamped(
        $(self.loadProductDetailsTarget).find('.cell-seller')
      )
    })

    this.autoCloseProductListing()
  }

  disconnect() {
    if (this.productListingObserver) {
      const productListing = document.querySelector(
        `#${this.collapseTargetValue}`
      )
      if (!productListing) return
      this.productListingObserver.unobserve(productListing)
    }
  }

  loadFiltersFromURL() {
    const urlParams = new URLSearchParams(window.location.search)
    this.ndc = urlParams.get('ndc') || ''
    this.keywords = urlParams.get('keywords') || ''
    this.sortDir = urlParams.get('sort_dir') || this.sortDir
    this.sortBy = urlParams.get('sort_by') || this.sortBy
    this.p2pOnly = urlParams.get('p2p_only') || ''
    this.hideNonNabp = urlParams.get('vawd_only') || ''
    this.hide_p2p = urlParams.get('hide_p2p') || ''
    this.showShortDate = urlParams.get('short_date') || ''
    this.hideShortDate = urlParams.get('hide_short_date') || ''
    this.manufacturer = urlParams.get('manufacturer') || ''
    this.seller_name = urlParams.get('seller_name') || ''
  }

  returnBrowserParams() {
    const params = new URLSearchParams()
    if (this.sourceValue) params.set('source', this.sourceValue)
    if (this.hideNonNabp) params.set('vawd_only', this.hideNonNabp)
    if (this.sourceValue == 'recently-viewed-ddi') {
      return params
    }
    if (this.ndc) params.set('ndc', this.ndc)
    if (this.keywords) params.set('keywords', this.keywords)
    if (this.sortBy) params.set('sort_by', this.sortBy)
    if (this.sortDir) params.set('sort_dir', this.sortDir)
    if (this.p2pOnly) params.set('p2p_only', this.p2pOnly)
    if (this.hide_p2p) params.set('hide_p2p', this.hide_p2p)
    if (this.showShortDate) params.set('short_date', this.showShortDate)
    if (this.hideShortDate) params.set('hide_short_date', this.hideShortDate)
    if (this.manufacturer) params.set('manufacturer', this.manufacturer)
    if (this.seller_name) params.set('seller_name', this.seller_name)
    // default search type params
    params.set('search_type', 'supplier_products')
    return params
  }

  autoCloseProductListing() {
    this.productListingObserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          $(`#${this.collapseTargetValue}`).collapse('hide')
        }
      },
      { threshold: [0], rootMargin: ROOT_MARGIN }
    )

    const productListing = document.querySelector(
      `#${this.collapseTargetValue}`
    )
    this.productListingObserver.observe(productListing)
  }

  expandProductDetails() {
    // Return if the product details are already loaded
    if (this.loadProductDetailsTarget.classList.contains('loaded')) {
      return
    }

    // Return if the product details are already loading
    if (this.expandProductDetailsInProgressValue) {
      return
    }
    this.loadFiltersFromURL()
    const params = this.returnBrowserParams()
    this.expandProductDetailsInProgressValue = true
    const stimulusThis = this
    $.ajax({
      type: 'GET',
      cache: false,
      dataType: 'script',
      url: `/products/${stimulusThis.ddiValue}/gpi_product_show?${
        params.toString() ? `${params.toString()}` : ''
      }`,
      complete: function (jqXHR, textStatus) {
        stimulusThis.expandProductDetailsInProgressValue = false
        $.addTitleIfLineClamped(
          $(stimulusThis.loadProductDetailsTarget).find('.cell-seller')
        )
      }
    })
  }

  toggleWholesalersForP2pProduct(event) {
    if (this.showRegularSellers) {
      this.collapseWholesalersForP2pProduct(event)
    } else {
      this.expandWholesalersForP2pProduct(event)
    }
  }

  collapseWholesalersForP2pProduct() {
    this.showRegularSellers = false
    const $product = $(`#product-ddi-${this.ddiValue}`)
    $product.find('.show-p2p-row ~ tr').remove()
    $product
      .find('.show-p2p-wholesalers')
      .html(I18n.t('marketplace.show_regular_wholesalers'))
    this.updateSellerCount()
  }

  expandWholesalersForP2pProduct(event) {
    if (this.expandWholesalersForP2p) {
      return
    }
    this.loadFiltersFromURL()
    const params = this.returnBrowserParams()
    params.delete('p2p_only')
    params.set('hide_p2p', 'hide_p2p')
    this.expandWholesalersForP2p = true
    const self = this
    $.ajax({
      type: 'GET',
      cache: false,
      dataType: 'json',
      url: `/products/${self.ddiValue}/gpi_product_show?${
        params.toString() ? `${params.toString()}` : ''
      }`,
      success: function (data) {
        self.showRegularSellers = true
        const $product = $(`#product-ddi-${self.ddiValue}`)
        $product
          .find('.show-p2p-wholesalers')
          .html(I18n.t('marketplace.hide_regular_wholesalers'))
        const tbody = $(`#product-ddi-${self.ddiValue}`).find('tbody')[0]
        const pagination = $(`#product-ddi-${self.ddiValue}`).find(
          '.list-pagination'
        )[0]
        tbody.insertAdjacentHTML('beforeend', data.entries)
        pagination.innerHTML = data.pagination
        self.expandWholesalersForP2p = false
        $('[data-toggle="ez-tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
        $(window).trigger('resize.stickyTableHeaders')
        ReactRailsUJS.mountComponents()
        $.addTitleIfLineClamped(
          $(self.loadProductDetailsTarget).find('.cell-seller')
        )
        self.updateSellerCount()
      },
      error: (jqXHR, textStatus, errorThrown) => {
        const $product = $(`#product-ddi-${stimulusThis.ddiValue}`)
        $product.find('.show-p2p-wholesalers').remove()
        alertify.error(I18n.t('stimulus_pagination.loading_error'))
        stimulusThis.expandWholesalersForP2p = false
      }
    })
  }

  updateSellerCount() {
    const value = this.showRegularSellers
      ? this.regularSellerCountValue + this.p2pSellerCountValue
      : this.p2pSellerCountValue
    this.sellerCountTarget.innerHTML =
      value > 1 ? `${value} Sellers` : `${value} Seller`
  }

  rph2rphLearnMoreButtonClick(e) {
    e.preventDefault()

    if (Cookies.get('p2p-splash-modal') === undefined) {
      Cookies.set('p2p-splash-modal', 'true', { expires: 2000 })
      $('#splashHiddenModalOpener').trigger('click')
    } else {
      $('#p2p-splash-modal').trigger('click')
    }
  }
}
