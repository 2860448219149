import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "popupInitiator" ]
  static values = {}

  connect() {}

  popupInitiatorTargetConnected(element) {
    const preDefinedModalStyleType = {
      "small": "modal-sm",
      "large": "modal-lg",
      "extraLarge": "modal-xl"
    }
    if(element.classList.contains("disabled")) {
      return
    }
    // this will be id of an element on which
    // we will open bootstrap modal
    // make sure it is the same ID
    // on which we want to open modal
    const uniqId = element.dataset["uniqId"]
    const insertAdjacentEleId = element.dataset["insertAdjacentEleId"]
    const insertAdjacentEleClasses = element.dataset["insertAdjacentEleClasses"]
    const loaderHtmlEleId = element.dataset["loaderHtmlEleId"]
    const modalStyleType = preDefinedModalStyleType[element.dataset["modalStyleType"]]
    const requestType = element.dataset["requestType"] || 'dynamic'
    if (!document.querySelector(`#${uniqId}`)) {
      let newEle = document.createElement("div")
      newEle.setAttribute("class", "modal fade")
      newEle.setAttribute("id", `${uniqId}`)
      newEle.setAttribute("role", "dialog")
      if (loaderHtmlEleId && document.querySelector(`#${loaderHtmlEleId}`)) {
        newEle.innerHTML = document.querySelector(`#${loaderHtmlEleId}`).innerHTML
        // remove static HTML now
        document.querySelector(`#${loaderHtmlEleId}`).remove()
      } else {
        // default loading HTML
        newEle.innerHTML = `<div id='${insertAdjacentEleId}' class='${insertAdjacentEleClasses} ${modalStyleType} modal-dialog'><div class="modal-content"><div class='modal-body'>Loading...</div></div></div>`
      }
      document.body.appendChild(newEle)
      const stimulusThis = this
      if (requestType === 'dynamic') {
        $(`#${uniqId}`).on('show.bs.modal', function (event) {
          event.stopImmediatePropagation()
          event.stopPropagation()
          stimulusThis.makeApiCall(element.dataset["url"], insertAdjacentEleId)
        })
      }
    }
  }


  makeApiCall(url, insertAdjacentEleId) {
    fetch(`${url}`, {
      method: "GET",
      headers: {
        Authorization: 'Web'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("something went wrong")
      } else {
        return response.text()
      }
    })
    .then(html => {
      const elem = document.querySelector(`#${insertAdjacentEleId}`)
      elem.innerHTML = ""
      elem.insertAdjacentHTML('afterbegin', html)
    })
    .catch(error => {
      document.querySelector(`#${insertAdjacentEleId}`).innerHTML = "Something went wrong. Please refresh page or contact our support via Intercom chat."
      console.error("Error:", error)
    })
  }

}
