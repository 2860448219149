import { Controller } from "@hotwired/stimulus"
import numeral from 'numeral'

export default class extends Controller {
  static targets = [
    'addProductErrorMessage',
    'currentPrice',
    'endDate',
    'form',
    'ndc',
    'price',
    'productListingTable',
    'quantity',
    'runUntilInactive',
    'startDate',
    'supplierProductId'
  ]

  // Target callbacks

  formTargetConnected(element) {
    $(element).validate() // Form validations
  }

  quantityTargetConnected() {
    this.refreshProductListing()
    this.calculateTotals()
  }

  quantityTargetDisconnected() {
    this.refreshProductListing()
    this.calculateTotals()
  }

  ndcTargetConnected(element) {
    this.bindAutoComplete(element)
  }

  startDateTargetConnected(element) {
    this.bindDatePicker(element)
  }

  endDateTargetConnected(element) {
    this.bindDatePicker(element)
  }

  // Methods

  offerQuantityChanged() {
    this.calculateTotals()
  }

  offerPriceChanged() {
    this.calculateTotals()
  }

  handleSubmit(e) {
    this.refreshProductListing()
    if (this.quantityTargets.length == 0) {
      e.preventDefault()
    }
  }

  runUntilInactiveChanged(e) {
    this.startDateTarget.value = ''
    this.endDateTarget.value = ''
    this.startDateTarget.disabled = e.target.checked
    this.endDateTarget.disabled = e.target.checked
  }

  removeFromBundle(e) {
    e.target.parentElement.parentElement.parentElement.remove()
  }

  removePhoto(e) {
    const currentForm = $(this.formTarget)
    $(e.target).parents('.file-preview').remove()
    if (currentForm.find('.file-preview').length == 0) {
      currentForm.find('.default-photo').removeClass('hide')
    }
  }

  addPhoto(e) {
    const currentForm = $(this.formTarget)
    for (const file of e.target.files) {
      // Check if file size if greater than 10MB, show error message
      if (file.size > 10 * 1024 * 1024) {
        alertify.error(I18n.t('promotion_bundles.upload_cant_exceed_10_mb'))
      } else {
        if (file.type.match(/image/)) {
          currentForm.find('.default-photo').addClass('hide')
          const thumbnailHtml = `<div class='file-preview position-relative margin-right-10 margin-bottom-10 inline-block'>
            <div class='img-thumbnail'>
              <a
                href='javascript:;'
              >
                <img
                  title=${file.name}
                  src=''
                  width='100'
                  height='100'
                />
              </a>
              <button
                type='button'
                class='btn btn-xs btn-danger position-absolute'
                data-action='suppliers--inventory--promotion-bundles#removePhoto'
              >
                <span class='icon-trash' aria-hidden='true'></span>
              </button>
            </div>
            <input class="hide" multiple="multiple" accept="image/*" type="file" name="promotion_bundle[photos][]">
          </div>`

          // append preview html
          let fileListWrapper = currentForm.find('.file-list-wrapper')
          let resultHtml = fileListWrapper.append(thumbnailHtml)
          let preview =
            $(resultHtml).find('img')[fileListWrapper.find('img').length - 1]
          var reader = new FileReader()
          reader.onload = function (e) {
            preview.src = e.target.result
          }
          reader.readAsDataURL(file)

          // add the valid files to new files target
          var dataTransfer = new DataTransfer()
          dataTransfer.items.add(file)
          fileListWrapper.find('input[type=file]')[
            fileListWrapper.find('input[type=file]').length - 1
          ].files = dataTransfer.files
        } else {
          alertify.error(I18n.t('promotion_bundles.upload_only_images'))
        }
      }
    }

    e.target.value = '' // clear current input
  }

  // Private

  refreshProductListing() {
    if (this.quantityTargets.length == 0) {
      this.addProductErrorMessageTarget.classList.remove('hide')
      this.productListingTableTarget.classList.add('hide')
    } else {
      this.addProductErrorMessageTarget.classList.add('hide')
      this.productListingTableTarget.classList.remove('hide')
    }
  }

  calculateTotals() {
    let currentForm = $(this.formTarget)
    let bundlePriceTotal = 0
    let regularPriceTotal = 0

    this.quantityTargets.forEach((element, index) => {
      bundlePriceTotal += element.value * this.priceTargets[index].value
      regularPriceTotal +=
        element.value * Number(this.currentPriceTargets[index].innerHTML)
    })

    currentForm
      .find('.bundle-price-total')
      .html(numeral(bundlePriceTotal).format('$0.00'))
    currentForm
      .find('.regular-price-total')
      .html(numeral(regularPriceTotal).format('$0.00'))
  }

  bindDatePicker(element) {
    const promoModifierDatePicker = $(element)
      .datepicker({
        changeYear: false,
        dateFormat: 'M dd'
      })
      .focus(function () {
        $('.ui-datepicker-year').hide()
      })

    $(element)
      .parents('.modal')
      .on('scroll', () => {
        promoModifierDatePicker.datepicker('hide')
        promoModifierDatePicker.blur()
      })

    $(element)
      .parents('.modal')
      .on('resize', () => {
        promoModifierDatePicker.datepicker('hide')
        promoModifierDatePicker.blur()
      })
  }

  bindAutoComplete(element) {
    const stimulusThis = this
    $(element).autocomplete({
      open: function () {
        setTimeout(function () {
          $('.ui-autocomplete').css('z-index', 1051)
        }, 0)
      },
      source: function (request, response) {
        $.ajax({
          type: 'GET',
          cache: false,
          dataType: 'json',
          url: '/suppliers/promotion_bundles/search',
          data: {
            search: $(element).val()
          },
          headers: {
            Authorization: 'Web'
          },
          beforeSend() {
            $('.ndc-search-loading').show()
          },
          success(data) {
            $('.ndc-search-loading').hide()

            response(
              $.map(data, function (item) {
                return {
                  label: `${item.ndc} - ${item.name} - ${item.package_size}`,
                  value: '',
                  item_id: item.id
                }
              })
            )
          },
          error(jqXHR, textStatus, errorThrown) {
            console.error('something went wrong')
            $('.ndc-search-loading').hide()
          }
        })
      },
      select: function (event, ui) {
        const supplierProductIds = stimulusThis.supplierProductIdTargets.map(
          (t) => Number(t.value)
        )

        if (supplierProductIds.includes(ui.item.item_id)) {
          setTimeout(function () {
            $('.ndc-input').val('')
          }, 100)
          alertify.error(I18n.t('promotion_bundles.product_already_added'))
        } else {
          $.get(
            `/suppliers/promotion_bundles/add_to_bundle?id=${ui.item.item_id}`
          )
        }
      },
      minLength: 3
    })
  }
}
