import { Controller } from '@hotwired/stimulus'
import numeral from 'numeral'

export default class extends Controller {
  static targets = ['messageDisplayElement', 'packPriceElement']

  static values = {
    cartQuantity: { type: Number, default: 0 },
    currentlyActivePromoModifiers: { type: Array, default: [] },
    isDeal: { type: Boolean, default: false },
    isOffer: { type: Boolean, default: false },
    isSpecialDiscountSupplier: { type: Boolean, default: false },
    maxQty: Number,
    quantity: { type: Number, default: 1 },
    supplierProductVariantId: Number,
    supplierProductPrice: Number
  }

  connect() {
    document.addEventListener(`cartQuantityUpdated${this.supplierProductVariantIdValue}`, (event) =>
      this.cartQuantityUpdated(event)
    )
  }

  disconnect() {
    document.removeEventListener(`cartQuantityUpdated${this.supplierProductVariantIdValue}`, (event) =>
      this.cartQuantityUpdated(event)
    )
  }

  cartQuantityUpdated(event) {
    this.cartQuantityValue = event.detail.cartQuantity
  }

  updateQty(event) {
    if (this.maxQtyValue >= event.currentTarget.value) {
      this.quantityValue = event.currentTarget.value
    } else {
      this.quantityValue = 1
    }
  }

  quantityValueChanged() {
    this.refreshQtyRequiredMessage()
    this.refreshPackPrice()
  }

  refreshQtyRequiredMessage() {
    // Return if there is no message display element
    if (!this.hasMessageDisplayElementTarget) {
      return
    }

    if (this.currentlyActivePromoModifiersValue.length == 0) {
      return
    }

    let promoModifiers = this.currentlyActivePromoModifiersValue
    let quantity = this.quantityValue + this.cartQuantityValue
    let nearestPromoModifier = promoModifiers.find(
      (promoModifier) => promoModifier.triggering_quantity > quantity
    )
    let promoMsg = ''

    if (nearestPromoModifier) {
      promoMsg = nearestPromoModifier.end_date
        ? I18n.t('inventory.promotions.required_qty_message_with_end_date', {
            quantity: nearestPromoModifier.triggering_quantity,
            price: numeral(nearestPromoModifier.promo_pack_price).format(
              '$0,0.00'
            ),
            end_date: moment(nearestPromoModifier.end_date).format('MM.DD.YYYY')
          })
        : I18n.t('inventory.promotions.required_qty_message', {
            quantity: nearestPromoModifier.triggering_quantity,
            price: numeral(nearestPromoModifier.promo_pack_price).format(
              '$0,0.00'
            )
          })
    }

    let resultHtml = nearestPromoModifier
      ? `<span class="teal teal-tooltip-lg promo-required-qty-message margin-top-5 inline-block" data-toggle="ez-tooltip" data-placement="bottom" title="" data-original-title="${promoMsg}">
        ${I18n.t('inventory.promotions.save_more')}
      </span>`
      : ''
    this.messageDisplayElementTarget.innerHTML = resultHtml
    $('[data-toggle="ez-tooltip"]').tooltip()
  }

  refreshPackPrice() {
    // Return if there is no pack price element
    if (!this.hasPackPriceElementTarget) {
      return
    }

    let activePromoModifiers = this.currentlyActivePromoModifiersValue
    let quantity = this.quantityValue + this.cartQuantityValue
    let applicablePromoModifers =
      !this.isOfferValue && !this.isDealValue && activePromoModifiers.length > 0
        ? activePromoModifiers.filter(
            (pm) => pm.triggering_quantity <= quantity
          )
        : []
    let appliedPromoModifier =
      applicablePromoModifers.length > 0
        ? applicablePromoModifers[applicablePromoModifers.length - 1]
        : null
    let packPriceHtml = ''

    if (appliedPromoModifier) {
      packPriceHtml = `
      <strike>${numeral(this.supplierProductPriceValue).format(
        '$0,0.00'
      )}</strike>
      <div class="teal">
        ${numeral(appliedPromoModifier.promo_pack_price).format('$0,0.00')}
      </div>
      <small class="teal">${I18n.t('global.promo_price')}</small>
      `
    } else if (this.isSpecialDiscountSupplierValue) {
      packPriceHtml = `
        <span class="discount-tooltip">
          <strong class="teal">${numeral(this.supplierProductPriceValue).format(
            '$0,0.00'
          )}*</strong>
          <small class="teal" data-toggle="ez-tooltip" data-placement="top" title="" data-original-title="${I18n.t(
            'wholesalers.special_discount_tooltip_for_pharmacy'
          )}">
            ${I18n.t('wholesalers.estimated_net_label')}
          </small>
        </span>
      `
    } else {
      packPriceHtml = `<strong>${numeral(this.supplierProductPriceValue).format(
        '$0,0.00'
      )}</strong>`
    }

    this.packPriceElementTarget.innerHTML = packPriceHtml
    $('[data-toggle="ez-tooltip"]').tooltip()
  }
}
