import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // Methods

  parentPermissionChanged(e) {
    $(e.target)
      .parent()
      .closest('.row')
      .find('.fields .child-permission')
      .prop('checked', e.target.checked)
  }

  childPermissionChanged(e) {
    let dataset = e.target.dataset
    let closestRow = $(e.target).parent().closest('.row')
    let childCollection = closestRow.find('.fields .child-permission')
    if (dataset.permissionName == 'application_operations') {
      // If the user enables the application_operations permission, we also need to enable the customers permission
      if (e.target.checked) {
        closestRow
          .find('[data-permission-name="customers"]')
          .prop('checked', true)
      }
    } else if (dataset.permissionName == 'customers') {
      // If the user disables the customers permission, we also need to disable the application_operations permission
      if (e.target.checked == false) {
        closestRow
          .find('[data-permission-name="application_operations"]')
          .prop('checked', false)
      }
    } else if (dataset.permissionName == 'manage_offers') {
      // If the user enables the manage_offers permission, we also need to enable the view_offers permission
      // applicable to both Regular and Rph2Rph offers
      if (e.target.checked) {
        closestRow
          .find('[data-permission-name="view_offers"]')
          .prop('checked', true)
      }
    } else if (dataset.permissionName == 'view_offers') {
      // If the user disables the view_offers permission, we also need to disable the manage_offers permission
      // applicable to both Regular and Rph2Rph offers
      if (e.target.checked == false) {
        closestRow
          .find('[data-permission-name="manage_offers"]')
          .prop('checked', false)
      }
    } else if (
      dataset.permissionName == 'edit_and_cancel' ||
      dataset.permissionName == 'return'
    ) {
      // If the user enables the edit_and_cancel permission, we also need to enable the view_orders permission
      // applicable to both Regular and Rph2Rph sections
      if (e.target.checked) {
        closestRow
          .find('[data-permission-name="view_orders"]')
          .prop('checked', true)
      }
    } else if (dataset.permissionName == 'view_orders') {
      // If the user disables the view_orders permission, we also need to disable the edit_and_cancel and return permissions
      // applicable to both Regular and Rph2Rph sections
      if (e.target.checked == false) {
        closestRow
          .find('[data-permission-name="edit_and_cancel"]')
          .prop('checked', false)
        closestRow
          .find('[data-permission-name="return"]')
          .prop('checked', false)
      }
    }

    closestRow
      .find('.parent-permission')
      .prop('checked', childCollection.not(':checked').length == 0)
  }
}
