import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pharmacy--leave-review"
export default class extends Controller {
  static targets = [
    'leaveReviewForm',
    'name',
    'position',
    'review',
    'sendReview',
    'closeReview'
  ]

  connect() {
    this.valuesChanged()
  }

  validForm() {
    return (
      this.nameTarget.value &&
      this.positionTarget.value &&
      this.reviewTarget.value
    )
  }

  valuesChanged() {
    if (this.validForm()) {
      this.sendReviewTarget.classList.remove('disabled')
      this.sendReviewTarget.disabled = false
    } else {
      this.sendReviewTarget.classList.add('disabled')
      this.sendReviewTarget.disabled = true
    }

    if (
      this.nameTarget.value ||
      this.positionTarget.value ||
      this.reviewTarget.value
    ) {
      this.closeReviewTarget.innerHTML = I18n.t(
        'global.close_and_save_for_later'
      )
    }
  }

  handleSubmit(e) {
    const stimulusThis = this

    if (this.validForm()) {
      this.sendReviewTarget.disabled = true

      $.ajax({
        type: 'PUT',
        cache: false,
        url: '/api/v1/users/review',
        data: $(this.leaveReviewFormTarget).serializeArray(),
        headers: {
          Authorization: 'Web'
        },
        complete: function (jqXHR, textStatus) {
          window.alertify.success(jqXHR.responseJSON.message)
          stimulusThis.sendReviewTarget.disabled = false
          $('#leaveReview').modal('hide')
          Turbo.visit(window.location.pathname)
        }
      })
    }
  }

  handleCloseReview(e) {
    const stimulusThis = this
    const formData = $(this.leaveReviewFormTarget).serializeArray()
    formData.push({ name: 'close_and_save', value: true })
    this.closeReviewTarget.disabled = true

    $.ajax({
      type: 'PUT',
      cache: false,
      url: '/api/v1/users/review',
      data: formData,
      headers: {
        Authorization: 'Web'
      },
      complete: function (jqXHR, textStatus) {
        stimulusThis.closeReviewTarget.disabled = false
        $('#leaveReview').modal('hide')
      }
    })
  }
}
