import { Controller } from '@hotwired/stimulus'
import { getLocalTime } from '../utilities/timeZoneHelper'

export default class extends Controller {
  static values = {
    cutoff: String
  }

  connect() {
    if (this.hasCutoffValue) {
      let localTime = getLocalTime(this.cutoffValue)
      this.element.textContent = I18n.t(
        'cart.shipping_available_until_cutoff',
        { cutoff: `${localTime.formattedDate}` }
      )
    }
  }
}
