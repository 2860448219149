import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['supplierProductWrapper']

  sortProducts() {
    this.supplierProductWrapperTarget.innerHTML =
      '<div class="no-results loading"><div class="sk-wave"><div class="sk-rect sk-rect1"></div><div class="sk-rect sk-rect2"></div><div class="sk-rect sk-rect3"></div><div class="sk-rect sk-rect4"></div><div class="sk-rect sk-rect5"></div></div></div>'
  }
}
