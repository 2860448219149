import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    elementIdentifier: String
  }

  initialize() {
    const stmThis = this
    $(`${stmThis.elementIdentifierValue}`).popover({
      html: true,
      container: "body",
      // custom popover HTML
      template: '<div class="popover" role="tooltip"><div class="arrow tooltip-arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    })
  }

}
