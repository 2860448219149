import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  static values = {
    supplierProductId: String,
    supplierId: String,
    modalUniqId: String
  }

  submit(event) {
    event.preventDefault()
    fetch(`/api/v1/wholesalers/${this.supplierIdValue}/apply_controls`, {
      method: "POST",
      headers: {
        Authorization: 'Web'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("something went wrong")
      } else {
        return response.json()
      }
    })
    .then(_data => {
      const event = new CustomEvent(`control-btn-${this.supplierIdValue}`, {
        detail: {
          applicationSent: true
        }
      })
      document.dispatchEvent(event)
      window.alertify.success(I18n.t('controlled.application_sent'))
      // js way to close bootstrap modal
      $(`#${this.modalUniqIdValue}`).modal("hide")
    })
    .catch(error => {
      $(`#${this.modalUniqIdValue}`).modal("hide")
      console.error("Error:", error)
    })
  }

}
