import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'

// Connects to data-controller="swiper"
export default class extends Controller {

  static targets = [ "nextBtn", "previousBtn" ]

  connect() {
    this.initializeSwiper()
  }

  initializeSwiper() {
    const swiperOptions = {
      slidesPerView: 6,
      slidesPerGroup: 4,
      loop: false,
      direction: 'horizontal',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    }

    new Swiper(this.element, swiperOptions)

    if (this.element.swiper.isBeginning) {
      this.previousBtnTarget.classList.add("hide")
    }
  }

  next() {
    this.element.swiper.slideNext()
    if (this.element.swiper.isEnd) {
      this.nextBtnTarget.classList.add("hide")
    }
    if (!this.element.swiper.isBeginning) {
      this.previousBtnTarget.classList.remove("hide")
    }
  }

  previous() {
    this.element.swiper.slidePrev()
    if (this.element.swiper.isBeginning) {
      this.previousBtnTarget.classList.add("hide")
    }
    if (!this.element.swiper.isEnd) {
      this.nextBtnTarget.classList.remove("hide")
    }
  }

}
