import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['purchaseHistoryElement']

  static values = {
    supplierProductId: String,
    supplierId: String,
    ajaxInProgress: Boolean
  }

  loadPurchaseHistory() {
    $(`#showPurchaseHistory${this.supplierProductIdValue}`).modal('show')
    // Return if the purchase histories are already loaded
    if (this.purchaseHistoryElementTarget.classList.contains('loaded')) {
      return
    }

    // Return if the purchase histories are already loading
    if (this.ajaxInProgressValue) {
      return
    }

    this.ajaxInProgressValue = true
    const stimulusThis = this
    $.ajax({
      type: 'GET',
      cache: false,
      dataType: 'script',
      data: { supplier_id: stimulusThis.supplierIdValue },
      url: `/products/${stimulusThis.supplierProductIdValue}/show_purchase_histories`,
      complete: function (jqXHR, textStatus) {
        stimulusThis.ajaxInProgressValue = false
        stimulusThis.purchaseHistoryElementTarget.classList.add('loaded')
      }
    })
  }
}
