import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['viewedProductListing']

  static values = {
    ajaxCallInProgress: { type: Boolean, default: false }
  }

  viewedProductListingTargetConnected() {
    this.loadViewedProductListing()
  }

  loadViewedProductListing() {
    if (this.ajaxCallInProgressValue) {
      return
    }

    this.ajaxCallInProgressValue = true

    const resultTarget = this.viewedProductListingTarget

    $.ajax({
      url: '/api/v1/marketplace/recently_viewed_ddi',
      type: 'GET',
      cache: false,
      dataType: 'html',
      headers: {
        Authorization: 'Web'
      },
      data: {},
      error: function (e) {
        resultTarget.innerHTML = 'Something went wrong'
        this.ajaxCallInProgressValue = false
      },
      success: function (html) {
        resultTarget.innerHTML = ''
        resultTarget.insertAdjacentHTML('afterbegin', html)
        this.ajaxCallInProgressValue = false
      }
    })
  }
}
