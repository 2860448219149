import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'sortByRequestedOn'
  ]

  handleSort() {
    const currentParams = window.location.search;
    const hasPageParam = (currentParams.indexOf("?page") == 0), pageParam = `?page=${$.getURLParam('page')}`;
    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    const baseUrlWithPageParam = [location.protocol, '//', location.host, location.pathname, pageParam].join('');
    const selectedOption = this.sortByRequestedOnTarget.value
    const sortDir = selectedOption === 'Oldest first' ? 'created_at+asc' : 'created_at+desc'
    Turbo.visit(
      hasPageParam ? decodeURIComponent(`${baseUrlWithPageParam}&q%5Bs%5D=${sortDir}`) : decodeURIComponent(`${baseUrl}?q%5Bs%5D=${sortDir}`)
    )
  }
}