import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "applyControlBtn" ]

  changeAndDisableApplyBtn(event) {
    if(event && event.detail.applicationSent) {
      this.applyControlBtnTargets.forEach( function(element) {
        element.textContent = "Pending for controls"
        element.classList.add("disabled")
        element.removeAttribute("data-toggle")
      })
    }
  }

}
