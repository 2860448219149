import { Controller } from "@hotwired/stimulus"

const loader = '<td class="text-center"><img alt="Loading" id="img-loading" style="width: 10%;" src="/assets/loading.gif"></td>';

export default class extends Controller {
  connect() {
    // The listener for the Bootstrap event via jQuery
    $(this.currentModalTarget).on('hidden.bs.modal', (event) => {
      this.markSavedNotificationsAsRead()
    })
  }

  static targets = [
    'currentModal',
    'filterByOrdersOffersButton',
    'filterByOrdersButton',
    'filterByOffersButton',
    'filterByBundlesDiscountsButton',
    'filterByVolumeDiscountsButton',
    'filterByBundlesButton',
    'bundlesDiscountsContent',
    'ordersOffersContent'
  ]

  // Target callbacks

  currentModalTargetConnected() {
    // TODO-Kalpesh:
    // Uncomment this line when enable this feature again
    // this.showModal();
  }

  // Actions

  filterByOrdersOffers(event) {
    this.filterByOrdersButtonTarget.classList.add('neutral');
    this.filterByOffersButtonTarget.classList.add('neutral');
    this.filterByOrdersOffersButtonTarget.classList.remove('neutral');
    this.ordersOffersContentTarget.innerHTML = loader
    this.fetchModalContent(['order', 'offer']);
  }

  filterByOrders(event) {
    this.filterByOrdersOffersButtonTarget.classList.add('neutral');
    this.filterByOffersButtonTarget.classList.add('neutral');
    this.filterByOrdersButtonTarget.classList.remove('neutral');
    this.ordersOffersContentTarget.innerHTML = loader
    this.fetchModalContent(['order']);
  }

  filterByOffers(event) {
    this.filterByOrdersOffersButtonTarget.classList.add('neutral');
    this.filterByOrdersButtonTarget.classList.add('neutral');
    this.filterByOffersButtonTarget.classList.remove('neutral');
    this.ordersOffersContentTarget.innerHTML = loader
    this.fetchModalContent(['offer']);
  }

  filterByBundlesDiscounts(event) {
    this.filterByVolumeDiscountsButtonTarget.classList.add('neutral');
    this.filterByBundlesButtonTarget.classList.add('neutral');
    this.filterByBundlesDiscountsButtonTarget.classList.remove('neutral');
    this.bundlesDiscountsContentTarget.innerHTML = loader
    this.fetchModalContent(['deal', 'bundle', 'volume_discount']);
  }

  filterByVolumeDiscounts(event) {
    this.filterByBundlesDiscountsButtonTarget.classList.add('neutral');
    this.filterByBundlesButtonTarget.classList.add('neutral');
    this.filterByVolumeDiscountsButtonTarget.classList.remove('neutral');
    this.bundlesDiscountsContentTarget.innerHTML = loader
    this.fetchModalContent(['volume_discount']);
  }

  filterByBundles(event) {
    this.filterByBundlesDiscountsButtonTarget.classList.add('neutral');
    this.filterByVolumeDiscountsButtonTarget.classList.add('neutral');
    this.filterByBundlesButtonTarget.classList.remove('neutral');
    this.bundlesDiscountsContentTarget.innerHTML = loader
    this.fetchModalContent(['bundle']);
  }

  // Private

  showModal(){
    let currentModalTarget = $(this.currentModalTarget)
    if (currentModalTarget.length > 0) {
      currentModalTarget.modal({show: true});
      this.fetchModalContent(['order', 'offer'])
      this.fetchModalContent(['volume_discount', 'bundle', 'deal'])
    }
  }

  fetchModalContent(type) {
    const stimulusThis = this
    $.ajax({
      type: 'GET',
      cache: false,
      dataType: 'script',
      url: `/pharmacy/saved_notifications`,
      data: {
        content_type: type
      },
      headers: { Authorization: 'Web' },
      error(jqXHR, textStatus, errorThrown) {
        console.error(getAjaxError(jqXHR))
      }
    })
  }

  markSavedNotificationsAsRead() {
    $.ajax({
      type: 'PUT',
      cache: false,
      dataType: 'script',
      url: `/pharmacy/saved_notifications/mark_as_read`,
      headers: { Authorization: 'Web' },
      error(jqXHR, textStatus, errorThrown) {
        console.error(getAjaxError(jqXHR))
      }
    })
  }
}