import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    source: String,
    hasProducts: Boolean,
    keywordsParam: String,
    isMultipleFiltered: Boolean
  }

  connect() {
    if (this.sourceValue === 'recently-viewed-ddi' && !this.hasProductsValue) {
      document.getElementById("recentlyViewedProducts").classList.add("hide")
    }
    if (this.sourceValue) return
    const isKeywordNoResult = Boolean(
      !this.hasProductsValue &&
        this.keywordsParamValue &&
        !this.isMultipleFilteredValue
    )

    $('.show-selected-filters').toggleClass('hidden', isKeywordNoResult)
    $('.reset-selected-filters').toggleClass('hidden', !isKeywordNoResult)

    ReactRailsUJS.mountComponents()
  }
}
