import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = [
    'subscriptionRepContactSaveBtn',
    'subscriptionContactName',
    'subscriptionContactEmail'
  ]

  connect() {
    this.checkContactDetail()
  }

  storeContactName() {
    Cookies.set('subscription_rep_name', this.subscriptionContactNameTarget.value, {expires: 1000});
    this.checkContactDetail()
  }

  storeContactEmail() {
    Cookies.set('subscription_rep_email', this.subscriptionContactEmailTarget.value, {expires: 1000});
    this.checkContactDetail()
  }

  checkContactDetail() {
    if(this.subscriptionContactNameTarget.value.length > 0 && this.subscriptionContactEmailTarget.value.length > 0 && this.validEmail()) {
      this.enableSubmitBtn()
    } else {
      this.disableSubmitBtn()
    }
  }

  checkValidEmail() {
    this.validEmail() ? this.enableSubmitBtn() : this.disableSubmitBtn()
  }

  enableSubmitBtn() {
    this.subscriptionRepContactSaveBtnTarget.disabled = false;
    this.subscriptionRepContactSaveBtnTarget.classList.remove('disabled');
  }

  disableSubmitBtn() {
    this.subscriptionRepContactSaveBtnTarget.disabled = true;
    this.subscriptionRepContactSaveBtnTarget.classList.add('disabled');
  }

  validEmail() {
    const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const personalEmailDomains = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "aol.com"];
    const email = this.subscriptionContactEmailTarget.value;
    const domain = email.split('@')[1];

    return (emailFormatRegex.test(email) && personalEmailDomains.indexOf(domain.toLowerCase()) === -1)
  }

}