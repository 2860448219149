import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inCartMessage', 'itemQty', 'quantityInput']
  static values = {
    changeTabAfterAddedToCart: { type: Boolean, default: false },
    maxQty: Number,
    qty: { type: String, default: '1' }
  }
  // static outlets = [ "cart--tabs" ]

  connect() {}

  updateQty(event) {
    if (this.maxQtyValue < event.currentTarget.value) {
      const error = I18n.t('api.cart.qty_not_available', {
        count: this.maxQtyValue
      })
      window.alertify.error(error)
      this.quantityInputTarget.value = 1
      this.qtyValue = 1
    } else {
      this.qtyValue = event.currentTarget.value
    }
  }

  add(event) {
    const { variantId, supplierId } = event.params

    if (!variantId || !supplierId) {
      return
    }

    const stimulusThis = this
    const formData = new FormData()
    formData.append('variant_id', variantId)
    formData.append('quantity', this.qtyValue)
    fetch(`/api/v1/cart/${supplierId}?source=stimulus`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Web'
      }
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorObj) => {
            throw new Error(errorObj.message)
          })
        } else {
          return response.json()
        }
      })
      .then((data) => {
        const event = new CustomEvent(`updateCartTotal`, {
          detail: {
            newOrderCreated: data.new_order_created,
            alreadyAddedToCart: data.already_added,
            itemPrice: parseFloat(data.line_item_price),
            itemRewardPoint: data.item_reward_point
          }
        })
        document.dispatchEvent(event)

        $(`.open-cart-message-${supplierId}`).html(
          I18n.t('cart.in_cart_products', {
            count: data.line_items_count,
            amount: numeral(data.order_total).format('$0,0.00')
          })
        )

        if (stimulusThis.hasInCartMessageTarget) {
          stimulusThis.inCartMessageTarget.innerHTML = I18n.t(
            'cart.in_cart_items',
            { count: data.line_item_qty }
          )
        }
        const updateOpenCartShippingMessageEvent = new CustomEvent(
          `updateOpenCartShippingMessage${supplierId}`,
          {
            detail: {
              cartTotal: data.order_total
            }
          }
        )
        document.dispatchEvent(updateOpenCartShippingMessageEvent)

        const cartQuantityUpdatedEvent = new CustomEvent(
          `cartQuantityUpdated${data.line_item_variant_id}`,
          {
            detail: {
              cartQuantity: data.line_item_qty
            }
          }
        )
        document.dispatchEvent(cartQuantityUpdatedEvent)
        window.alertify.success(`${data.product_name} has been added to cart.`)
        // if (this.changeTabAfterAddedToCartValue) {
        //   const tabTarget = this.cartTabsOutlet.itemsListTarget
        //   tabTarget.innerHTML = ""
        //   tabTarget.insertAdjacentHTML('afterbegin', html)

        //   this.cartTabsOutlet.toggleActiveClassTargets.forEach( function(element) {
        //     element.classList.remove("active")
        //     if (element.dataset["tabName"] == "itemsTab") {
        //       element.classList.add("active")
        //     }
        //   })
        // }
      })
      .catch((err) => {
        window.alertify.error(err)
      })
  }
}
