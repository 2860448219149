import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    $('body').on('click', '#splash-screen', function () {
      $('#infoHiddenModalOpener').trigger('click')
    })

    $('body').on('click', '.details-expansion', function () {
      var $parentTr = $(this).closest('tr')
      if ($parentTr.hasClass('border-blue')) {
        $parentTr.removeClass('border-blue')
      } else {
        $parentTr.addClass('border-blue')
      }
    })

    $('body').on('enabledStickiness.stickyTableHeaders', 'table', function () {
      $(this).find('thead').addClass('is-sticky')
    })

    $('body').on('disabledStickiness.stickyTableHeaders', 'table', function () {
      $(this).find('thead').removeClass('is-sticky')
    })
  }
}
