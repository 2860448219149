import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['entries', 'pagination', 'loadingEntries']

  static values = {
    ajaxCallInProgress: { type: Boolean, default: false },
    rootMargin: { type: String, default: '100px' },
    list: String
  }

  initialize() {
    let options = {
      rootMargin: this.rootMarginValue
    }

    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      options
    )
  }

  loadingEntriesTargetConnected(element) {
    this.intersectionObserver.observe(element)
  }

  loadingEntriesTargetDisconnected(element) {
    this.intersectionObserver.unobserve(element)
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    if (this.ajaxCallInProgressValue) {
      return
    }

    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      return
    }
    let url = next_page.href
    this.ajaxCallInProgressValue = true
    const self = this

    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        $('.loading-pagination-entries').remove()
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.ajaxCallInProgressValue = false
        $('[data-toggle="ez-tooltip"]').tooltip()
        $(window).trigger('resize.stickyTableHeaders')
        ReactRailsUJS.mountComponents()

        const event = new CustomEvent(`${self.listValue}:entriesLoaded`)
        document.dispatchEvent(event)
      },
      error: (jqXHR, textStatus, errorThrown) => {
        $('.loading-pagination-entries').remove()
        alertify.error(I18n.t('stimulus_pagination.loading_error'))
        this.ajaxCallInProgressValue = false
      }
    })
  }
}
