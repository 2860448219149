/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Support component names relative to this directory:
var ReactRailsUJS = require('react_ujs')

// Here are list of components folders that we are using with react_ujs,
// we will only import index.js file from each folder
// AdCards/index
// ArrivingToday/index
// Audit/index
// Auth/TwoFactor/index
// Common/index
// Common/QuickInfoModal/index
// Common/SearchHistory/index
// CreditMeter/index
// Dashboard/index
// Deals/index
// FeatureWalkthrough/index
// GroupDeals/index
// MakeOffer/index
// MarketingCampaign/index
// Marketplace/index
// Messages/Composer/index
// Messages/index
// Notifications/index
// Offers/index
// Orders/index
// ProductRequest/index
// Products/index
// Products/Favorites/index
// Referral/index
// Reports/index
// Returns/index
// ShippedItemInfo/index
// ShoppingCart/index
// WholesalerCard/index
// WholesalerDetails/index
// Wholesalers/index
var componentRequireContext = require.context(
  'components',
  true,
  /(AdCards\/index|ArrivingToday\/index|Audit\/index|Auth\/TwoFactor\/index|Common\/index|Common\/QuickInfoModal\/index|Common\/SearchHistory\/index|CreditMeter\/index|Dashboard\/index|Deals\/index|FeatureWalkthrough\/index|GroupDeals\/index|MakeOffer\/index|MarketingCampaign\/index|Marketplace\/index|Messages\/index|Messages\/Composer\/index|Notifications\/index|Offers\/index|Orders\/index|ProductRequest\/index|Products\/index|Products\/Favorites\/index|Referral\/index|Reports\/index|Returns\/index|ShippedItemInfo\/index|ShoppingCart\/index|WholesalerCard\/index|WholesalerDetails\/index|Wholesalers\/index)/
)
ReactRailsUJS.useContext(componentRequireContext)

import 'regenerator-runtime/runtime'
import '@hotwired/turbo-rails'

import 'controllers'
