import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pharmacy--gln-number"
export default class extends Controller {
  static targets = [
    'addGlnNumberForm',
    'glnNumber',
    'glnNumberShip',
    'sglnNumber',
    'addGlnNumber',
    'fillLater'
  ]

  connect() {
    $('#addGlnNumber').modal('show')
  }

  validForm() {
    return (
      this.glnNumberTarget.value &&
      this.glnNumberTarget.value.length === 13 &&
      (!this.glnNumberShipTarget.value ||
        (this.glnNumberShipTarget.value &&
          this.glnNumberShipTarget.value.length === 13)) &&
      (!this.sglnNumberTarget.value ||
        (this.sglnNumberTarget.value &&
          this.sglnNumberTarget.value.length === 15))
    )
  }

  handleSubmit(e) {
    e.preventDefault()

    const stimulusThis = this

    if (this.validForm()) {
      this.addGlnNumberTarget.disabled = true

      $.ajax({
        type: 'POST',
        cache: false,
        url: '/api/v1/wholesalers/application/business_information',
        data: $(this.addGlnNumberFormTarget).serializeArray(),
        headers: {
          Authorization: 'Web'
        },
        complete: function (jqXHR, textStatus) {
          window.alertify.success(jqXHR.responseJSON.message)
          stimulusThis.addGlnNumberTarget.disabled = false
          $('#addGlnNumber').modal('hide')
          Turbo.visit('/')
        }
      })
    }
  }

  handleFillLater(e) {
    this.addGlnNumberTarget.disabled = true
    this.fillLaterTarget.disabled = true
    $('#addGlnNumber').modal('hide')
    Turbo.visit('/')
  }
}
