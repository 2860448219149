import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  userDropdownChanged(e) {
    window.location =
      '/pharmacy/settings/activities?user_id=' +
      e.target.value +
      '&range=' +
      $("#activities-filter-form [name='daterange']").val()
  }
}