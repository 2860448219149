import { Controller } from '@hotwired/stimulus'
import numeral from 'numeral'

export default class extends Controller {
  static targets = [
    'addToCartButton',
    'currentModal',
    'currentPrice',
    'offerQuantity',
    'price',
    'quantity',
    'quantityUnavailableMsg',
    'restrictedQuantityReachedMsg',
    'stock'
  ]

  static values = {
    bundleId: Number,
    maxPurchaseQuantity: Number,
    purchasedQty: Number,
    restrictQuantity: Boolean
  }

  // Target callbacks

  currentModalTargetConnected() {
    this.checkForStockAndRestrictions()
  }

  // Methods

  quantityChanged() {
    this.checkForStockAndRestrictions()
    this.calculateTotals()
  }

  handleAddToCartSubmit(e) {
    const stimulusThis = this
    this.addToCartButtonTarget.disabled = true
    if (Number(this.quantityTarget.value) > 0) {
      $.ajax({
        type: 'POST',
        cache: false,
        dataType: 'script',
        url: `/pharmacy/bundles/${stimulusThis.bundleIdValue}/add_to_cart`,
        data: {
          quantity: stimulusThis.quantityTarget.value
        },
        headers: {
          Authorization: 'Web'
        },
        complete: function (jqXHR, textStatus) {
          stimulusThis.addToCartButtonTarget.disabled = false
        }
      })
    } else {
      window.alertify.error(
        I18n.t('promotion_bundles.errors.qty_should_be_more_than_zero')
      )
      this.addToCartButtonTarget.disabled = false
    }
  }

  // Private

  checkForStockAndRestrictions() {
    let stockUnavailable = this.checkForOutOfStockProducts()
    let maxPurchaseQuantityReached = this.checkForRestrictedQuantities()

    if (this.hasAddToCartButtonTarget) {
      this.addToCartButtonTarget.disabled =
        stockUnavailable || maxPurchaseQuantityReached
    }
  }

  checkForOutOfStockProducts() {
    let stockUnavailable = false
    this.offerQuantityTargets.forEach((element, index) => {
      if (
        Number(element.innerHTML) * this.quantityTarget.value >
        numeral(this.stockTargets[index].innerHTML).value()
      ) {
        stockUnavailable = true
      }
    })

    if (stockUnavailable) {
      this.quantityUnavailableMsgTarget.classList.remove('hide')
    } else {
      this.quantityUnavailableMsgTarget.classList.add('hide')
    }

    return stockUnavailable
  }

  checkForRestrictedQuantities() {
    if (!this.restrictQuantityValue) {
      return false
    }

    let maxPurchaseQuantityReached = false

    if (
      this.purchasedQtyValue + Number(this.quantityTarget.value) >
      this.maxPurchaseQuantityValue
    ) {
      maxPurchaseQuantityReached = true
    }

    if (maxPurchaseQuantityReached) {
      this.restrictedQuantityReachedMsgTarget.classList.remove('hide')
    } else {
      this.restrictedQuantityReachedMsgTarget.classList.add('hide')
    }

    return maxPurchaseQuantityReached
  }

  calculateTotals() {
    let currentModalTarget = $(this.currentModalTarget)
    let bundlePriceTotal = 0
    let regularPriceTotal = 0
    this.offerQuantityTargets.forEach((element, index) => {
      bundlePriceTotal +=
        Number(element.innerHTML) *
        numeral(this.priceTargets[index].innerHTML).value() *
        this.quantityTarget.value
      regularPriceTotal +=
        Number(element.innerHTML) *
        numeral(this.currentPriceTargets[index].innerHTML).value() *
        this.quantityTarget.value
    })

    currentModalTarget
      .find('.bundle-price-total')
      .html(numeral(bundlePriceTotal).format('$0.00'))
    currentModalTarget
      .find('.regular-price-total')
      .html(numeral(regularPriceTotal).format('$0.00'))
  }
}
