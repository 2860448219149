import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    shippingMethod: String
  }
  static targets = [
    'counterOfferShippingMethods',
    'selectMethod',
    'selectShippingMethod'
  ]

  selectShippingMethodsChange() {
    this.selectShippingMethodTarget.classList.add("hide")
    const { checked } = this.counterOfferShippingMethodsTarget
    this.selectMethodTargets.forEach((method) => {
      method.checked = checked && method.name === this.shippingMethodValue
      method.disabled = !checked
    })
  }

  selectShippingMethod(event) {
    this.selectShippingMethodTarget.classList.add("hide")
    this.selectMethodTargets.forEach((method) => {
      method.checked = method.name === event.currentTarget.name
    })
  }
}