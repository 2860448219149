import moment from 'moment';
import 'moment-timezone';

export const getLocalTime = (estTime) => {
  if(!estTime) {
    return {}
  }
  let estDate = moment.tz("America/New_York");
  const minuteSecs = estTime.trim().split(':')
  estDate.set("hour", parseInt(minuteSecs[0]) + 12); //we need 24 hour format for Date
  estDate.set("minute", parseInt(minuteSecs[1]));
  const hours = convertServerDateToLocal(estDate).get("hour");
  const minutes = convertServerDateToLocal(estDate).get("minute")
  const formattedDate = moment(convertServerDateToLocal(estDate)).format("hh:mm A")
  return { hours: hours, minutes: minutes, formattedDate: formattedDate };
};

export const convertServerDateToLocal = (dateInput) => {
  let offset = 5.0;
  offset = moment.tz("America/New_York").isDST() ? (offset - 1) : offset;

  let utc = dateInput.valueOf() - (-dateInput.utcOffset() * 60000);

  let clientDate = moment(new Date(utc + (3600000 * offset)));
  
  return clientDate;
};